export function onInitialClientRender() {
  // Initial render should scroll to top
  window.scrollTo(0, 0);
}

export function shouldUpdateScroll({ routerProps: { location } }) {
  // Whenever route changes, scroll to top
  if (location.action === "PUSH") {
    window.scrollTo(0, 0);
  }
  return false;
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location.hash) {
    const id = location.hash.replace("#", "");
    const element = document.getElementById(id);

    console.log(element);
    if (element) {
      setTimeout(() => {
        window.scrollTo({
          top: element.offsetTop - 130,
          behavior: "smooth",
        });
      }, 200);
    }
  } else {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }
};
